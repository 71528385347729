import { RouteConfig } from 'vue-router';

const routes = new Array<RouteConfig>(
    {
        path: 'workspace/:codWorkspace/funil-oportunidade',
        name: 'OportunidadeRouter',
        component: () => import('@/views/oportunidade/OportunidadeRouterView.vue'),
        children: [{
            path: ':codFunil?',
            name: 'FunilView',
            component: () => import('@/views/oportunidade/OportunidadeView.vue')
        },
        {
            path: ':codFunil/:codFunilOportunidade',
            name: 'DetalheOportunidadeFunil',
            component: () => import('@/views/oportunidade/OportunidadeEditView.vue'),
            children: [{
                path: 'proposta/:codOportunidadeOrcamento',
                name: 'OportunidadeProposta',
                component: () => import('@/views/oportunidade/Components/Orcamento/Dialog/DialogOrcamento.vue')
            }]
        },
        ]
    },
)

export default routes