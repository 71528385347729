import { Base64 } from "js-base64";

const AUTH_LOCAL_STORAGE_KEY = "easypipe-lembrar-acesso";
const keyCompanyName = process.env.VUE_APP_COMPANY_NAME
  ? `${process.env.VUE_APP_COMPANY_NAME.toLowerCase()}-`
  : "";

const keysStorage = [
  `${keyCompanyName}codigo`,
  `${keyCompanyName}token`,
  `${keyCompanyName}nome`,
  `${keyCompanyName}ehPrimeiroAcesso`,
  `${keyCompanyName}cpf`,
  `${keyCompanyName}logo`,
  `${keyCompanyName}workspace`,
];

interface IDadosAcesso {
  email: string;
  senha: string;
}

const lembrarDadosAcesso = (email: string, senha: string): void => {
  const data = JSON.stringify({
    email,
    senha,
  });

  localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, Base64.encode(data));
};

const limparDadosAcesso = (): void => {
  localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
};

const obterDadosAcesso = (): IDadosAcesso | null => {
  const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!data) return null;
  return JSON.parse(Base64.decode(data));
};

const limparStorage = (): void => {
    keysStorage.forEach((x) => localStorage.removeItem(x));
};

export {
  lembrarDadosAcesso,
  limparDadosAcesso,
  obterDadosAcesso,
  limparStorage,
  keyCompanyName,
  keysStorage
};
