import { RouteConfig } from "vue-router";
import allowAccess from './guards/admin.guard';

const adminRoutes: Array<RouteConfig> = [{
  path: '/workspace/:codWorkspace/admin',
  name: 'adminRouter',
  beforeEnter: allowAccess,
  component: () => import('@/views/admin/RouterView.vue'),
  children: [
    {
      name: '',
      path: '',
      component: () => import('@/views/admin/IndexView.vue'),
    },
    {
      path: 'motivo-perda/:id',
      name: 'MotivoPerda',
      component: () => import('@/views/admin/motivo-perda/RouterView.vue'),
      children: [
        {
          name: 'Index',
          path: '',
          component: () => import('@/views/admin/motivo-perda/IndexView.vue'),
        },
        {
          name: 'Gerenciar',
          path: 'gerenciar/:codMotivoPerda?',
          component: () => import('@/views/admin/motivo-perda/EditView.vue'),
        },
      ]
    },
    {
      path: 'template-email/:id',
      name: 'TemplateEmail',
      component: () => import('@/views/admin/template-email/RouterView.vue'),
      children: [
        {
          name: 'Index',
          path: '',
          component: () => import('@/views/admin/template-email/IndexView.vue'),
        },
        {
          name: 'Gerenciar',
          path: 'gerenciar/:codTemplateEmail?',
          component: () => import('@/views/admin/template-email/EditView.vue'),
        },
      ]
    },
    {
      path: 'template-proposta/:id',
      name: 'templateProposta',
      component: () => import('@/views/admin/template-proposta/RouterView.vue'),
      children: [
        {
          name: '',
          path: '',
          component: () => import('@/views/admin/template-proposta/IndexView.vue'),
        },
      ]
    },
    {
      name: 'Empresa',
      path: 'empresa/:id',
      component: () => import('@/views/admin/empresa/IndexView.vue'),
    },
    {
      name: 'Categoria',
      path: 'categoria/:id',
      component: () => import('@/views/admin/categoria/IndexView.vue'),
    },
    {
      name: 'Assinatura',
      path: 'assinatura/:id',
      component: () => import('@/views/admin/assinatura/IndexView.vue'),
    },
    {
      name: 'Topico',
      path: 'topico/:id',
      component: () => import('@/views/admin/topico/IndexView.vue'),
    },
    {
      name: 'FormaPgto',
      path: 'forma-pgto/:id',
      component: () => import('@/views/admin/forma-pgto/IndexView.vue'),
    },
    {
      name: 'Cliente',
      path: 'cliente/:id',
      component: () => import('@/views/admin/cliente/IndexView.vue'),
    },
    {
      name: 'Contato',
      path: 'contato/:id',
      component: () => import('@/views/admin/contato/IndexView.vue'),
    },
    {
      name: 'Fornecedor',
      path: 'fornecedor/:id',
      component: () => import('@/views/admin/fornecedor/IndexView.vue'),
    },
    {
      name: 'Fabricante',
      path: 'fabricante/:id',
      component: () => import('@/views/admin/fabricante/IndexView.vue'),
    },
    {
      name: 'Produto',
      path: 'produto/:id',
      component: () => import('@/views/admin/produto/IndexView.vue'),
    },
    {
      name: 'PrazoEntrega',
      path: 'prazo-entrega/:id',
      component: () => import('@/views/admin/prazo-entrega/IndexView.vue'),
    },
    {
      name: 'Usuario',
      path: 'usuario/:id',
      component: () => import('@/views/admin/usuario/IndexView.vue'),
    },
    {
      name: 'MinhaConta',
      path: 'minha-conta/:id',
      component: () => import('@/views/admin/minha-conta/IndexView.vue'),
    },
    {
      name: 'Automacoes',
      path: 'automacao/:id',
      component: () => import('@/views/admin/automacao/RouterView.vue'),
      children: [
        {
          name: 'Index',
          path: '',
          component: () => import('@/views/admin/automacao/IndexView.vue'),
        },
        {
          name: 'Gerenciar',
          path: 'gerenciar/:codAutomacao?',
          component: () => import('@/views/admin/automacao/gerenciar/Index.vue')
        }]
    },
    {
      name: 'Funil',
      path: 'funil/:id',
      component: () => import('@/views/admin/funil/RouterView.vue'),
      children: [
        {
          name: 'Index',
          path: '',
          component: () => import('@/views/admin/funil/IndexView.vue'),
        },
        {
          name: 'Editar',
          path: ':codFunil',
          component: () => import('@/views/admin/funil/EditView.vue')
        }]
    },
  ],
}]


export default adminRoutes;