import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store/index";
import adminRoutes from "./admin/index";
import oportunidadeRoutes from "./oportunidade/oportunidade.router";
import workspaceRoutes from "./workspace/workspace.router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/aceitar-convite",
    component: () => import("@/layouts/PublicLayout.vue"),
    meta: {
      auth: false,
    },
    children: [
      {
        path: ':codUsuarioEmpresaWorkspace',
        name: 'AceitarConviteView',
        component: () => import('@/views/aceitar-convite/AceitarConviteView.vue')
      },
    ],
  },
  {
    path: "/login",
    component: () => import("@/layouts/LoginLayout.vue"),
    meta: {
      auth: false,
    },
    children: [
      {
        path: "",
        name: "login",
        component: () => import("@/views/login/LoginView.vue"),
      }
    ],
  },
  {
    path: "/publico",
    component: () => import("@/layouts/PublicLayout.vue"),
    meta: {
      auth: false,
    },
    children: [
      {
        path: "proposta/visualizar/:hash",
        name: "OrcamentoPublico",
        component: () => import("@/views/publico/orcamento/Orcamento.vue"),
        meta: {
          title: "Proposta",
        },
      },
    ],
  },
  ...workspaceRoutes,
  {
    path: "/",
    redirect: 'workspaces',
    meta: {
      auth: true,
    },
    component: () => import("@/layouts/AppLayout.vue"),
    children: [
      ...adminRoutes,
      ...oportunidadeRoutes,
      {
        path: "workspace/:codWorkspace/meus-negocios",
        name: "MeusNegocios",
        component: () => import("../views/meus-negocios/MeusNegociosView.vue"),
      },
      {
        path: "workspace/:codWorkspace/calendario",
        name: "calendario",
        component: () => import("../views/calendario/CalendarioView.vue"),
      },
      {
        path: "workspace/:codWorkspace/relatorio",
        name: "relatorio",
        component: () => import("../views/relatorio/Router.vue"),
        children: [
          {
            path: "",
            name: "IndexView",
            component: () => import("@/views/relatorio/IndexView.vue"),
          },
        ],
      },
      {
        path: "workspace/:codWorkspace/acesso-negado",
        name: "AcessoNegado",
        component: () => import("../views/acesso-negado/Index.vue"),
      },
    ],
  },
  {
    path: "/:patchMatch(.*)*",
    component: () => import("@/views/nao-encontrado/NaoEncontrado.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    if (store.getters.usuarioEstalogado) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
