import { httpClient } from "@/core/http/api.http.service";
import store from "@/store";
import router from "@/router";

httpClient.interceptors.response.use(undefined, function (error) {
  if (error) {
    if (error.response.status === 401) {
      store.commit("AUTH_LOGOUT");
      return router.push("/login");
    }

    if (error.response.status === 403) {
      return router.push("/acesso-negado");
    }
  }
});

httpClient.interceptors.request.use(
  function (config) {
    const workspace = router.currentRoute.params['codWorkspace'];
    config.headers.set("workspace", workspace);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
