import { RouteConfig } from 'vue-router';

const routes = new Array<RouteConfig>({
  path: '/workspaces',
  component: () => import('@/layouts/WorkspaceLayout.vue'),
  meta: {
    auth: true,
  },
  children: [
    {
      path: '',
      name: 'WorkspaceRouter',
      component: () => import('@/views/workspace/IndexView.vue'),
    },
  ],
});

export default routes;
