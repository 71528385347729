const WORKSPACES = `WORKSPACES`;
const CREDENTIALS = "CREDENTIALS";
const TOGGLE_MENU = `TOGGLE_MENU`;
const AUTH_LOGOUT = `AUTH_LOGOUT`
const AUTH_SUCCESS = `AUTH_SUCCESS`
const AUTH_LOGOUT_REQUEST = `AUTH_LOGOUT_REQUEST`
const AUTH_REQUEST = `AUTH_REQUEST`

export default {
  WORKSPACES,
  CREDENTIALS,
  TOGGLE_MENU,
  AUTH_LOGOUT,
  AUTH_SUCCESS,
  AUTH_REQUEST,
  AUTH_LOGOUT_REQUEST
};
